import { css } from '@emotion/react';
import { NoSsr, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Types } from '@scorenco/core';

import { Shape, ShapeLayout } from '../../illustrations';
import { Image, Markdown, Wrapper } from '../../primitives';

type HeroSliceProps = {
  slice: Types.ComponentSlicesHero;
};

export const HeroSlice = ({ slice }: HeroSliceProps) => {
  const images = slice.images?.data;
  const nbImages = images?.length || 0;

  return (
    <Wrapper>
      <Stack
        gap={{ xs: 2, sm: 4, lg: 16 }}
        alignItems="stretch"
        direction={{ xs: 'column', md: 'row' }}
        minHeight={nbImages > 0 ? '40vh' : '30vh'}
      >
        <Stack
          flex={1}
          gap={2}
          textAlign={{ xs: 'center', md: nbImages > 0 ? 'left' : 'center' }}
          justifyContent="center"
        >
          <Typography
            variant="h1"
            component="h1"
            css={(theme) => css`
              strong {
                color: ${theme.vars.palette.secondary.main};
              }
            `}
          >
            <Markdown value={slice.title} isInline />
          </Typography>
          {slice.chapeau && (
            <Typography variant="subtitle1">
              <Markdown value={slice.chapeau} isInline />
            </Typography>
          )}
          {slice.text && <Markdown value={slice.text} marginTop={1} />}
        </Stack>

        {nbImages > 0 && (
          <Box
            css={(theme) => css`
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              flex: 1;

              ${theme.breakpoints.up('md')} {
                justify-content: end;

                margin-top: -${theme.spacing(8)};
                margin-bottom: -${theme.spacing(8)};
              }
            `}
          >
            <NoSsr>
              {nbImages === 1 && (
                <>
                  {/* <Shape
                    css={(theme) => css`
                      max-width: 20rem;

                      ${theme.breakpoints.up('md')} {
                        position: absolute;
                        top: -10%;
                        bottom: -10%;
                        right: -20%;
                        height: 120%;
                        max-width: 120%;
                      }
                    `}
                  /> */}
                  <Image
                    src={images?.[0].attributes?.url}
                    css={(theme) => css`
                      display: block;
                      width: 100%;
                      margin-top: ${theme.spacing(4)};
                      margin-bottom: ${theme.spacing(4)};
                    `}
                  />
                </>
              )}

              {/*
            {nbImages === 2 && (
              <>
                <Image
                  src={images?.[0].attributes?.url}
                  css={(theme) => css`
                    display: block;
                    border-bottom-left-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                    // aspect-ratio: 2 / 3;
                    object-fit: cover;
                    min-width: 0;
                    height: 70%;
                  `}
                />
                <Image
                  src={images?.[1].attributes?.url}
                  css={(theme) => css`
                    display: block;
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;
                    align-self: end;
                    // aspect-ratio: 2 / 3;
                    object-fit: cover;
                    min-width: 0;
                    height: 70%;
                  `}
                />
              </>
            )}
            */}

              {/* {nbImages === 3 && (
              <Box
                css={css`
                  position: absolute;
                  top: 70%;
                  transform: translateY(-50%);
                  padding-top: 2rem;
                  padding-bottom: 2rem;
                  left: 0;
                  right: 0;
                  max-width: 30rem;
                `}
              >
                <ShapeLayout
                  width="100%"
                  variant={nbImages === 3 ? 'alt' : 'default'}
                >
                  {images?.map((image, index) => {
                    const url = image.attributes?.url;
                    const delay = (index + 1) * 0.2;

                    return url ? (
                      <Shape
                        key={image.attributes?.url}
                        src={url}
                        sx={{
                          animation: `wait ${delay}s, moveUp 0.5s ease-in-out ${delay}s`,
                        }}
                      />
                    ) : null;
                  })}
                </ShapeLayout>
              </Box>
            )} */}

              {nbImages > 3 && (
                <ShapeLayout
                  width="100%"
                  variant={nbImages === 3 ? 'alt' : 'default'}
                  css={css`
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    max-width: 30rem;

                    @keyframes wait {
                      0% {
                        opacity: 0;
                      }
                      100% {
                        opacity: 0;
                      }
                    }

                    @keyframes moveUp {
                      0% {
                        opacity: 0;
                        transform: translate(-100px, 100px);
                      }
                      100% {
                        opacity: 1;
                        transform: translate(0, 0);
                      }
                    }

                    @keyframes moveDown {
                      0% {
                        opacity: 0;
                        transform: translate(100px, -100px);
                      }
                      100% {
                        opacity: 1;
                        transform: translate(0, 0);
                      }
                    }
                  `}
                >
                  {images?.map((image, index) => {
                    const url = image.attributes?.url;
                    const delay = (index + 1) * 0.2;

                    return url ? (
                      <Shape
                        key={image.attributes?.url}
                        src={url}
                        sx={{
                          animation: `wait ${delay}s, moveUp 0.5s ease-in-out ${delay}s`,
                        }}
                      />
                    ) : null;
                  })}
                </ShapeLayout>
              )}
            </NoSsr>
          </Box>
        )}
      </Stack>
    </Wrapper>
  );
};
