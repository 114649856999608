import { useId } from 'react';

import { NoSsr } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

export type ShapeProps = BoxProps & {
  src?: string;
};

export const Shape = ({ src, ...rest }: ShapeProps) => {
  const id = useId();

  return (
    <NoSsr>
      <Box
        component="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 227 227"
        {...rest}
      >
        <defs>
          <mask id={`${'snc-shape-mask' + id}`}>
            <rect x="0" y="0" width="227" height="227" fill="black" />
            <path
              fill="white"
              d="M4.48528137,157.235281 L69.7647186,222.514719 C74.4510101,227.20101 82.0489899,227.20101 86.7352814,222.514719 L222.514719,86.7352814 C227.20101,82.0489899 227.20101,74.4510101 222.514719,69.7647186 L157.235281,4.48528137 C152.54899,-0.201010127 144.95101,-0.201010127 140.264719,4.48528137 L4.48528137,140.264719 C-0.201010127,144.95101 -0.201010127,152.54899 4.48528137,157.235281 Z"
            />
          </mask>
        </defs>
        {src ? (
          <image
            xlinkHref={src}
            width="227"
            height="227"
            preserveAspectRatio="xMidYMid slice"
            mask={`${'url(#snc-shape-mask' + id + ')'}`}
          />
        ) : (
          <path
            fill="#4433CC"
            d="M4.48528137,157.235281 L69.7647186,222.514719 C74.4510101,227.20101 82.0489899,227.20101 86.7352814,222.514719 L222.514719,86.7352814 C227.20101,82.0489899 227.20101,74.4510101 222.514719,69.7647186 L157.235281,4.48528137 C152.54899,-0.201010127 144.95101,-0.201010127 140.264719,4.48528137 L4.48528137,140.264719 C-0.201010127,144.95101 -0.201010127,152.54899 4.48528137,157.235281 Z"
          />
        )}
      </Box>
    </NoSsr>
  );
};
