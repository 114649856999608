import { css } from '@emotion/react';
import { BoxProps, Box } from '@mui/system';

const style = {
  root: css`
    position: relative;
  `,
  default: {
    content: css`
      height: 0;
      padding-top: 100%;

      & > * {
        position: absolute;
        width: ${(10 / 18) * 100}%;
        height: ${(10 / 18) * 100}%;

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: ${(0.43 / 18) * 100}%;
          right: ${(0.43 / 18) * 100}%;
        }

        &:nth-child(3) {
          bottom: ${(0.43 / 18) * 100}%;
          left: ${(0.43 / 18) * 100}%;
        }

        &:nth-child(4) {
          bottom: 0;
          right: 0;
        }
      }
    `,
  },
  alt: {
    content: css`
      height: 0;
      padding-top: 142%;

      > * {
        position: absolute;
        width: ${(10 / 17.5) * 100}%;
        height: ${(10 / 24.5) * 100}%;

        &:nth-child(1) {
          top: 0;
          right: ${(0.4 / 17.5) * 100}%;
        }

        &:nth-child(2) {
          top: ${(7 / 24.5) * 100}%;
          left: 0;
        }

        &:nth-child(3) {
          top: ${(7.5 / 24.5) * 100}%;
          right: 0;
        }

        &:nth-child(4) {
          bottom: 0;
          left: ${(0.4 / 17.5) * 100}%;
        }
      }
    `,
  },
};

export type ShapeLayoutProps = BoxProps & {
  variant?: 'default' | 'alt';
};

export const ShapeLayout = ({
  variant = 'default',
  children,
  ...rest
}: ShapeLayoutProps) => (
  <Box css={style.root} {...rest}>
    <div css={style[variant].content}>{children}</div>
  </Box>
);
